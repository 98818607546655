export default function IconX(props = {}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class={(props.classList || '') + ' ' + (props.visible === false ? ' cwgi-hidden' : '')}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M18 6l-12 12" />
      <path d="M6 6l12 12" />
    </svg>
  )
}
